<template>
  <div>
    <MySubscriptionInformation v-if="getActivePlan.status" />
    <div v-else>
      <no-subscription />
    </div>
    <hr />
    <PlanUpdates
      v-if="getActivePlan.status"
      :getActivePlan="getActivePlan"
      class="mt-4"
    />
  </div>
</template>

<script>
import Modules from "../../mixins/Modules";
import { Currency } from "gurhan/helpers/Currency";
import { formatter } from "gurhan/helpers/Date";
import PlanUpdates from "./PlanUpdates.vue";
import NoSubscription from "./NoSubscription.vue";
import MySubscriptionInformation from "./Information.vue";
export default {
  mixins: [Modules],
  methods: {
    Currency,
    formatter,
    async CANCEL_SUBSCRIPTION(loading) {
      loading(true);
      const response = await this.$apiHeocademy.delete(
        `SubscriptionManagement/${this.getActivePlan.id}`
      );
      if (response.message === "OK") {
        this.alertBox = {
          status: true,
          message: "cancelledSubscription",
          variant: "success",
        };
        setTimeout(() => {
          location.reload();
        }, 3500);
      } else {
        this.alertBox = {
          status: true,
          message: "cancelledSubscriptionFailed",
          variant: "danger",
        };
      }
      setTimeout(() => {
        this.alertBox.status = false;
      }, 4000);
      loading(false);
    },
  },
  data() {
    return {
      showCancelPlan: false,
      alertBox: {
        status: false,
      },
    };
  },
  components: {
    PlanUpdates,
    NoSubscription,
    MySubscriptionInformation,
  },
};
</script>

<style></style>
