<template>
  <div>
    <ModuleLocked
      :title="$t('noPackageTitle')"
      :description="$t('noPackageDescription')"
    />
  </div>
</template>

<script>
import ModuleLocked from "../Packages/ModuleLocked.vue";
export default {
  components: {
    ModuleLocked,
  },
};
</script>

<style></style>
