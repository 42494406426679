<template>
  <div class="p-2">
    <h3>{{ $t("subscriptionBillInvoices") }}</h3>
    <div class="mt-4">
      <Table
        :count="count"
        :loading="isLoading"
        no-search
        no-card
        :headers="headers"
        :items="items"
      >
        <template #updatedDate="{ item }">
          {{ formatter(item.updatedDate) }}
          <div>
            <small
              ><i>
                {{
                  $i18n.locale === "tr"
                    ? item.paymentStatusMessageTR
                    : item.paymentStatusMessageEN
                }}</i
              ></small
            >
          </div>
        </template>
        <template #packagePrice="{ item }">
          {{ Currency({ price: getActivePlan.price }, "USD") }}
          <div>
            <span
              class="badge px-2 py-1"
              :class="`badge-${item.isPaymentSuccess ? 'success' : 'danger'}`"
            >
              {{
                item.isPaymentSuccess
                  ? $t("isPaymentSucceed")
                  : $t("isPaymentFailed")
              }}
            </span>
          </div>
        </template>
      </Table>
      <div class="d-flex justify-content-center mt-5">
        <Pagination :page="page" :pages="pages" @change="CHANGE_PAGINATION" />
      </div>
    </div>
  </div>
</template>

<script>
import Table from "../Tables/index.vue";
import { Currency } from "gurhan/helpers/Currency";
import { formatter } from "gurhan/helpers/Date";
import Pagination from "gurhan/vue/BootstrapPagination.vue";
export default {
  components: {
    Table,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      count: 0,
      headers: [
        {
          title: this.$t("planHistory.id"),
          value: "id",
          col: 12,
          md: 6,
          lg: 3,
        },
        {
          title: this.$t("planHistory.planId"),
          value: "planId",
          col: 12,
          md: 6,
          lg: 3,
        },
        {
          title: this.$t("planHistory.payAmount"),
          value: "packagePrice",
          col: 12,
          md: 6,
          lg: 2,
        },
        {
          title: this.$t("planHistory.updatedAt"),
          value: "updatedDate",
          col: 12,
          md: 6,
          lg: 4,
        },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  props: {
    getActivePlan: {
      default: () => ({}),
    },
  },
  methods: {
    Currency,
    formatter,
    GET_PAGINATION_FROM_QUERY() {
      const q = this.$route.query;
      if (!isNaN(Number(q.page))) {
        this.page = Number(q.page);
      }
      this.GET_PLAN_UPDATES();
    },
    CHANGE_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page: page } });
      this.GET_PLAN_UPDATES();
    },
    async GET_PLAN_UPDATES() {
      this.isLoading = true;
      const response = await this.$apiHeocademy.get(
        `SubscriptionManagement/PlanUpdates?page=${this.page}&planId=${this.getActivePlan.id}&limit=10`
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      } else this.items = [];
      this.isLoading = false;
    },
  },
  mounted() {
    this.GET_PAGINATION_FROM_QUERY();
  },
};
</script>

<style></style>
