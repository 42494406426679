<template>
  <div>
    <MySubscription />
  </div>
</template>

<script>
import MySubscription from "../components/MySubscription/index.vue";
export default {
  components: {
    MySubscription,
  },
};
</script>

<style></style>
